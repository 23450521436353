import React from 'react';
import Hexagon from '../../components/hexagonComponent';
import PreviewCompatibleImage from '../../components/NetlifyCMS_comps/PreviewCompatibleImage';
import { Box } from '@mui/material';


const PhotoSize = '15';

const compare = (a, b) => {
  if (a.alt < b.alt) {
    return -1;
  }
  if (a.alt > b.alt) {
    return 1;
  }
  return 0;
};

const CreateHexagons = ({ data, cmsPreview }) => {
  data.sort(compare);
	const styles = {
		flipCard: {
			perspective: '60rem',
			cursor: 'pointer',
		},
		flipCardInner: {
			display: 'flex',
			alignItems: 'center',
			transition: 'transform 0.6s',
			transformStyle: 'preserve-3d',
			'&:hover': {
				transform: 'rotateY(180deg)',
			},
			'&:focus': {
				transform: 'rotateY(180deg)',
			},
		},
		flipCardFront: {
			backfaceVisibility: 'hidden',
			position: 'absolute',
		},
		flipCardBack: {
			backfaceVisibility: 'hidden',
			transform: 'rotateY(180deg)',
		},
	};

  return data.map((person, index) => {
    if (cmsPreview) {
      return (
        <div>
          <PreviewCompatibleImage imageInfo={person} />
        </div>
      );
    } else {
      return (
        <Box component="div" sx={styles.flipCard} key={index}>
          <Box component="div" sx={styles.flipCardInner}>
            <Box component="div" sx={styles.flipCardFront}>
              <Hexagon
                photoSize={PhotoSize}
                photo={person.image ? person.image.publicURL : null}
                alt={person.alt}
              />
            </Box>
            <Box component="div" sx={styles.flipCardBack}>
              <Hexagon photoSize={PhotoSize} innerText={person.alt} />
            </Box>
          </Box>
        </Box>
      );
    }
  });
};

export default CreateHexagons;
